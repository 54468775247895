import React, { useEffect, useState } from 'react';
import { Box, SimpleGrid, Spinner, Center, Button } from '@chakra-ui/react';

import QRCard from '@components/Common/QRCard';
import useGuestData from '@/usecase/use-guest-list';
import isDevelopmentMode from '@invitato/helpers/dist/isDevelopmentMode';

import {
  ENABLE_QR_INVITATION,
  DISABLED_QR_GENERATOR_IN_PRODUCTION,
} from '@/constants/feature-flags';

export default function GenerateQRPage() {
  // IF this invitation not using QR code, just return nothing
  if (!ENABLE_QR_INVITATION) return null;

  const QR_PER_PAGE = 56;

  const { data, isLoading } = useGuestData(true);
  const [isMounted, setIsMounted] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const renderContent = () => {
    if (isLoading) return <div>Menyiapkan data..</div>;
    const PAGE = currentPage;

    const qrPerPage = data.filter((_, index) => {
      const result = index < QR_PER_PAGE * PAGE && index >= QR_PER_PAGE * (PAGE - 1);
      return result;
    });

    return qrPerPage.map((guest, index) => {
      if (index > 1000) {
        console.log('YOUR GUEST IS FUCKING A LOT!');
      }

      return <QRCard key={guest.code} name={guest.name} code={guest.code} desc={guest.desc} />;
    });
  };

  useEffect(() => {
    if (!isMounted) {
      setIsMounted(true);
    }
  }, []);

  if (!isMounted) {
    return (
      <Center height="100vh">
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor="gray.200"
          color="mainColorText"
          size="xl"
          margin="auto"
        />
      </Center>
    );
  }

  if (isMounted && !isDevelopmentMode() && DISABLED_QR_GENERATOR_IN_PRODUCTION) {
    return <Box>Sory, this disabled by admin.</Box>;
  }

  return (
    <Box maxWidth="1280px">
      <SimpleGrid minChildWidth="140px" spacing="40px">
        {renderContent()}
      </SimpleGrid>
      <Button
        marginTop="24px"
        onClick={() => setCurrentPage(1 + currentPage)}
      >{`Current Page - ${currentPage}`}</Button>
    </Box>
  );
}
